<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page class="column justify-center items-center area-auth">
        <div
          class="col-auto"
          style="padding: 3em 0 2em"
        >
          <LogoBlue />
        </div>

        <div class="col input-card shadow-0">
          <q-toolbar
            v-if="step"
            class="toolbar row items-center justify-between"
          >
            {{ step }}

            <small>v{{ version }}</small>
          </q-toolbar>

          <div :class="['q-pa-md', step ? 'border' : '']">
            <div
              class="q-pb-md"
              v-if="auth.cognitoInfo || auth.cognitoError"
            >
              <AuthInfo
                v-model="auth.cognitoInfo"
                v-if="auth.cognitoInfo"
              />

              <AuthError
                v-model="auth.cognitoError"
                v-if="auth.cognitoError"
              />
            </div>

            <router-view />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapState } from 'vuex'
import AuthInfo from '../components/AuthInfo'
import AuthError from '../components/AuthError'
import LogoBlue from '@/assets/LogoBlue.svg'
import { version } from '../../../../package.json'

export default {
  components: {
    LogoBlue,
    AuthInfo,
    AuthError
  },

  computed: {
    ...mapState({
      auth: ({ authStore }) => authStore
    }),

    version: () => version,

    step () {
      const { meta } = this.$route
      return meta.step
    }
  }
}
</script>

<style lang="sass">
  .area-auth
    .input-card
      border-radius: 5px
      width: 80vw
      max-width: 400px

    .input-card .toolbar
      color: white
      border-top-right-radius: 5px
      border-top-left-radius: 5px
      background: #0094D9
      height: 40px
      min-height: 40px
      max-height: 40px

    .border
      border-left: 1px solid #143b6352
      border-right: 1px solid #143b6352
      border-bottom: 1px solid #143b6352
      border-bottom-left-radius: 5px
      border-bottom-right-radius: 5px

    .image
      height: 150px
</style>
