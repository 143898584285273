<template>
  <q-banner
    dense
    v-show="info"
    inline-actions
    rounded
    class="bg-positive text-white"
  >
    <span>{{ $t(info) }}</span>

    <template #action>
      <q-btn
        dense
        icon="mdi-close"
        flat
        @click="info = ''"
      />
    </template>
  </q-banner>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    }
  },

  data () {
    const info = this.value
    return { info }
  },

  watch: {
    value (value) {
      if (this.info !== value) this.info = value
    }
  }
}
</script>
