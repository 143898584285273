<template>
  <q-banner
    dense
    v-show="error"
    inline-actions
    rounded
    class="bg-red text-white"
  >
    <span v-html="$t(error)" />

    <template #action>
      <q-btn
        dense
        size="sm"
        padding="0"
        icon="mdi-close"
        flat
        @click="error = ''"
      />
    </template>
  </q-banner>
</template>

<script>
export default {
  props: {
    value: {}
  },

  data () {
    const error = this.value
    return { error }
  },

  watch: {
    value (value) {
      if (this.error !== value) this.error = value
    }
  }
}
</script>
